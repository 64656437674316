import React, { Component } from "react";
import styled from "styled-components";

class Calendar extends Component {
  render() {
    let schedule = {
      Friday: [
        {
          t: "6:00",
          e: "Camp Starts",
        },
        { t: "8:00", e: "Welcome" },
        { t: "8:30", e: "Fellowship" },
        { t: "11:00", e: "Curfew" },
      ],
      Saturday: [
        { t: "7:30", e: "Hot Breakfast" },
        { t: "9:00", e: "Workshops (Session One)" },
        { t: "10:30", e: "Morning Tea" },
        { t: "11:00", e: "Workshops (Session Two)" },
        { t: "12:30", e: "Lunch" },
        { t: "3:00", e: "Afternoon Tea" },
        { t: "6:00", e: "Dinner" },
        { t: "7:00", e: "Night Meeting" },
        { t: "9:30", e: "Supper" },
        { t: "9:50", e: "Prayer room" },
        { t: "11:00", e: "Curfew" },
      ],
      Sunday: [
        { t: "7:30", e: "Breakfast" },
        { t: "9:30", e: "Communion Meeting" },
        { t: "10:45", e: "Morning Tea" },
        { t: "11:00", e: "Packup / Cleanup" },
        { t: "12:00", e: "Lunch" },
        { t: "2:00", e: "Camp Ends" },
      ],
    };

    return (
      <StyledCalendar>
        <h1>Schedule</h1>
        <br />
        <div className="schedule-container" style={{ textAlign: "left" }}>
          {Object.keys(schedule).map((day) => (
            <React.Fragment key={day}>
              <h2>{day}</h2>
              <ul>
                {schedule[day].map((i) => (
                  <li className="schedule-item" key={i.t}>
                    <span className="time">{i.t}</span>
                    <div className="item-dots" />
                    <div className="item-name">{i.e}</div>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ))}
        </div>
      </StyledCalendar>
    );
  }
}

const StyledCalendar = styled.section`
  .schedule-container {
    margin: 0 auto;
    width: min(360px, 80vw);
  }
  .schedule-item {
    display: flex;
    justify-content: stretch;
  }

  .item-name {
    white-space: nowrap;
  }
  .time {
    white-space: nowrap;
    color: #313131;
  }
  .item-dots {
    height: 11px;
    flex: 100% 1;
    border-bottom: 2px dotted #ccc;
    margin-left: 9px;
    margin-right: 9px;
  }
`;

export default Calendar;
