import { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div
        className="font-plain text-black w-100 "
        style={{
          fontSize: "0.8em",
          background: "#ffeeff33",
        }}
      >
        <div className="d-flex justify-content-center mx-auto align-items-center ">
          <div className="d-flex justify-content-center  align-items-center">
            SEQ Youngies{" "}
            <span className="mx-3" style={{ fontSize: "2em" }}>
              •
            </span>
            2024
          </div>
          {/* <div>&copy; Revival Fellowship 2022</div> */}
        </div>
      </div>
    );
  }
}

export default Footer;
