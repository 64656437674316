import * as React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NavContainer = styled.div`
  a {
    color: unset;
  }
`;

const SEQNav = () => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <NavContainer>
      <Navbar
        expand="lg"
        onToggle={() => setIsExpanded(true)}
        expanded={isExpanded}
      >
        <Navbar.Brand className="ms-3">
          <div />
          {/* <img className="d-none d-lg-block" alt="" src={icon} /> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse
          className={isExpanded ? "open" : "closed"}
          id="basic-navbar-nav"
        >
          <div className="navbar-collapse-header align-items-center flex-column d-flex position-relative">
            <div
              onClick={() => setIsExpanded(false)}
              className="collapse-close position-absolute"
              style={{ right: "0", top: "0" }}
            >
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="navbar-toggler"
                id="navbar_global"
              >
                <span></span>
                <span></span>
              </Navbar.Toggle>
            </div>
          </div>
          <Nav className="m-auto">
            <Nav.Link
              as={Link}
              onClick={() => setIsExpanded(false)}
              className="p-2 ms-1 me-1"
              to="/#home"
            >
              SEQ Breakup 2024
            </Nav.Link>

            <Nav.Link
              as={Link}
              onClick={() => setIsExpanded(false)}
              className="p-2 ms-1 me-1"
              to="/register"
            >
              Register
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </NavContainer>
  );
};

export default SEQNav;
