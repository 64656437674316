import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

interface HomeProps {}

const Home: React.FC<HomeProps> = ({}) => {
  return (
    <>
      <br />
      <div
        className="d-flex pt-5 flex-column align-items-center text-center "
        style={{
          width: "min(450px, 90% )",
          borderRadius: "1em",
          background: "#ffeedd",
        }}
      >
        <div
          style={{ fontSize: "3em", fontWeight: "700" }}
          className="title-font"
        >
          SEQ Breakup
        </div>
        <strong
          style={{ fontSize: "2em", fontWeight: "700" }}
          className="title-font"
        >
          Paris Olympics
        </strong>

        <br />
        <br />
        <div
          className="d-flex pt-5 flex-column align-items-center text-center"
          style={{
            borderRadius: "2em",
            width: "min(400px, 85% )",
          }}
        >
          <strong style={{ fontSize: "1.4em", fontWeight: "700" }}>
            23rd Nov 2024
          </strong>
          <br />
          <br />
          $8 Entry Fee
          <span>4pm Start</span>
          <span>
            <a
              className="text-black"
              href="https://goo.gl/maps/xfsgsEfWTRjpgi7b8"
            >
              Sunshine Coast Hall
            </a>
          </span>
          Dinner Provided
          <br />
          <br />
          <br />
          <Link to="/register" className="">
            <Button style={{ fontSize: "3em" }} className="btn-cta title-font">
              Apply Now
            </Button>
          </Link>
          <br />
          <br />
        </div>
        <div className="flex-grow-1" />
      </div>
      <Footer />
    </>
  );
};

export default Home;
